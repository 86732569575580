import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import VideoTypeCardUtil,{IVideoTypeCardDataObj} from './videoTypeCardUtil';
export default defineComponent({
    name:'VideoTypeCard',
    title:'视频类别',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IVideoTypeCardDataObj=reactive<IVideoTypeCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.ToolsProviderApi.buildUrl('/videoType')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                name: [utils.UtilPub.commonValidRule('请输入名称')],
            },
            otherParams:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new VideoTypeCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,beforeSaveHandler
         
        }
    }
});